Lyte.Component.register("crux-related-list-actions", {
_template:"<template tag-name=\"crux-related-list-actions\"> <template is=\"if\" value=\"{{expHandlers(cxPropRelatedList.module.api_name,'===','Attachments')}}\"><template case=\"true\"> <lyte-button id=\"attach_drop\" lt-prop-appearance=\"primary\" lt-prop-class=\"outlineprimary\"> <template is=\"registerYield\" yield-name=\"text\"> {{cruxGetI18n('crm.attach.option.label')}} </template> </lyte-button> <lyte-menu lt-prop-yield=\"true\" lt-prop-query=\"#attach_drop\" lt-prop-freeze=\"false\"> <template is=\"registerYield\" yield-name=\"yield\"> <lyte-menu-body> <template is=\"for\" items=\"{{cxPropRlActions}}\" item=\"item\" index=\"index\"> <lyte-menu-item onclick=\"{{action('relatedListActionClicked',this,item['data-cid'])}}\"> <lyte-menu-label>{{item.label}}</lyte-menu-label> </lyte-menu-item> </template> </lyte-menu-body> </template> </lyte-menu> </template><template case=\"false\"><template items=\"{{cxPropRlActions}}\" item=\"action\" index=\"index\" is=\"for\"> <lyte-button onclick=\"{{action('relatedListActionClicked',this,action['data-cid'])}}\" lt-prop-appearance=\"primary\" lt-prop-class=\"outlineprimary\"> <template is=\"registerYield\" yield-name=\"text\"> {{action.label}} </template> </lyte-button> </template></template></template> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"if","position":[1],"cases":{"true":{"dynamicNodes":[{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"text","position":[1]}]},{"type":"componentDynamic","position":[1]},{"type":"registerYield","position":[3,1],"dynamicNodes":[{"type":"attr","position":[1,1]},{"type":"for","position":[1,1],"dynamicNodes":[{"type":"attr","position":[1]},{"type":"text","position":[1,1,0]},{"type":"componentDynamic","position":[1,1]},{"type":"componentDynamic","position":[1]}]},{"type":"componentDynamic","position":[1]}]},{"type":"componentDynamic","position":[3]}]},"false":{"dynamicNodes":[{"type":"attr","position":[0]},{"type":"for","position":[0],"dynamicNodes":[{"type":"attr","position":[1]},{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"text","position":[1]}]},{"type":"componentDynamic","position":[1]}]}]}},"default":{}}],
_observedAttributes :["cxPropRelatedList","cxPropRlActions"],
	data : function(){
		return {
			cxPropRelatedList : Lyte.attr('object'), 
			cxPropRlActions : Lyte.attr('array')
		}		
	},
	actions : {
		// Functions for event handling
		relatedListActionClicked : function(elem,action){
			if(this.getMethods('relatedListAction')){
				this.executeMethod('relatedListAction',action,this.getData('cxPropRelatedList.id'),elem,event);
			}
		}
	},
	methods : {
		// Functions which can be used as callback in the component.
	}
});
